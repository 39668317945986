.breadcrumb-fluid {
    background-color: $bg-breadcrumb;
    background-image: linear-gradient(90deg, $bg-light-green-from, $bg-light-green-to);
    margin-bottom: 30px;
    z-index: 100;

    @media (min-width: 1200px) {
        .home & {
            margin-bottom: 42px;
        }
    }

    @media print {
        display: none;
    }

    &.affix {
        top: 86px;
        width: 100%;

        @media (min-width: 768px) and (max-width: 991px) {
            .navbar-static-top.navbar-open + .slider + .navbar-menu + & {
                top: 234px;
            }
        }

        @media (min-width: 992px) {
            top: 146px;
        }

        @media (min-width: 1200px) {
            top: 155px;
        }

        & + .container {
            @media (min-width: 768px) {
                margin-top: 66px;
            }

            @media (min-width: 992px) {
                margin-top: 128px;
            }

            @media (min-width: 1200px) {
                margin-top: 137px;
            }

            .no-fixing & {
                margin-top: 0 !important;
            }
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            .navbar-static-top.navbar-open + .slider + nav + & {
                top: 234px;
            }
        }
    }
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    text-transform: uppercase;

    & > li + li:before {
        content: "\003e";
        color: $text-red;
        font-size: 26px;
        font-weight: 200;
        line-height: 0;
        padding: 0;
        top: 2px;
        position: relative;
    }

    li {
        color: $text-red;

        &.active {
            color: $text-red;
            font-weight: 700;
        }

        a {
            color: $text-red;
        }
    }
}