.slider {
    background: $bg-green-light url(../img/headers/1_1.jpg) no-repeat center center;
    // todo?
    // background-image: url(../img/headers/1_1.jpg), linear-gradient(90deg, $bg-light-green-from, $bg-light-green-to);

    @media (max-width: 767px) {
        margin-top: 60px;
    }

    @media (min-width: 768px) {
        margin-top: 86px;
    }

    @media (max-width: 1199px) {
        background-position: top center;
    }

    @media (min-width: 1200px) {
        margin-top: 95px;
    }

    @media print {
        display: none;
    }

    .no-fixing & {
        margin-top: 0 !important;
    }

    .container {
        min-height: 250px;
        position: relative;

        @media (min-width: 1200px) {
            min-height: 350px;
        }

        .slogan {
            bottom: 40px;
            display: none;
            left: 20px;
            position: absolute;

            @media (min-width: 768px) {
                display: block;
            }
        }

        .help {
            background: url(../img/help-blok.png) no-repeat;
            background-size: contain;
            color: #fff;
            position: absolute;
            right: 20px;
            text-align: center;

            font-size: 24px;
            line-height: 28px;
            padding: 40px 15px 0 15px;
            top: 20px;
            height: 217px;
            width: 219px;

            @media (min-width: 1200px) {
                font-size: 34px;
                line-height: 39px;
                padding: 60px 15px 0 15px;
                top: 30px;
                height: 287px;
                width: 290px;
            }

            .btn {
                background-color: $bg-red;
                border-radius: 10px;
                color: #fff;
                font-size: 24px;
                margin-top: 10px;
                padding: 0 30px;
            }
        }
    }
}