.click-block {
    cursor: pointer;
}

.block {
    margin-bottom: 30px;

    a {
        color: $text-green;
    }

    img {
        max-width: 100%;

        @media (max-width: 991px) {
            max-width: 100% !important;
        }
    }

    &.block-border {
        border: 1px solid $border-green;
        padding: 15px;
    }

    &.block-padding {
        padding: 15px;

        & > h1:first-child {
            margin-top: 5px;
        }
    }

    .block-header {
        color: $text-red;
        font-size: 24px;
        min-height: 45px;
    }

    .block-content {
        color: $text-green;
    }

    &.block-video {
        .block-content {
            border: 1px solid $border-green;
            background-color: $bg-green-light;
            line-height: 268px; // todo???

            p {
                line-height: 1.42857143;
            }

            & + p {
                margin-top: 20px;
            }
        }
    }

    &.block-contact {
        .block-content {
            p {
                font-size: 16px;
                line-height: 32px;
                position: relative;

                &:before,
                &:after {
                    content: " ";
                    display: table;
                }
                &:after {
                    clear: both;
                }

                a {
                    float: left;
                    font-size: 18px;
                    line-height: 40px;
                    padding-left: 51px;
                    text-decoration: none;

                    &.red {
                        line-height: 24px;
                    }

                    img {
                        margin-right: 15px;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }

    &.block-html {
        .block-header {
            padding-bottom: 12px;
        }

        .block-content {
            line-height: 26px;
        }

        a {
            text-decoration: underline;
        }
    }

    &.block-html-border {
        border: 1px solid $border-green;
        padding: 15px;

        .block-header {
            line-height: 26px;
            padding-bottom: 12px;
        }

        .block-content {
            color: #333;
        }

        a {
            text-decoration: underline;
        }
    }

    &.block-photo {
        .block-content {
            border: 1px solid $border-green;
        }

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    &.block-links {
        .block-header {
            padding-bottom: 10px;
        }

        .block-content {
            ul {
                list-style-image: url('../img/li-dot.png');
                margin-bottom: 20px;
                padding-left: 36px;

                &:last-child {
                    margin-bottom: 0;
                }

                li {
                    min-height: 36px;

                    a {
                        border-bottom: 1px solid $text-green;
                        display: block;
                        line-height: 20px;
                        margin-left: -36px;
                        padding: 6px 0 6px 36px;
                        text-decoration: none;

                        @media (min-width: 992px) {
                            line-height: 42px;
                        }
                        @media (min-width: 1200px) {
                            line-height: 54px;
                        }
                    }

                    &:last-child a {
                        border-bottom: 0;
                    }
                }
            }
        }

        .border & .block-content,
        &.block-border .block-content {
            ul {
                border-top: 1px solid $border-green;
            }
        }

        .block-header + .block-content {
            ul {
                li {
                    a {
                        @media (min-width: 992px) {
                            line-height: 36px;
                        }
                        @media (min-width: 1200px) {
                            line-height: 46px;
                        }
                    }
                }
            }
        }

        &.block-links-mixed {
            .block-content {
                ul {
                    li {
                        padding-top: 10px;

                        @media (min-width: 992px) {
                            min-height: 42px;
                        }

                        a {
                            border-bottom: 0;
                            color: $text-red;
                            display: inline;
                            line-height: 23px;
                            padding-top: 0;
                            padding-bottom: 0;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    &.block-links-contact {
        background-color: $bg-green;
        padding: 0 15px;

        .block-content {
            color: #fff;

            ul {
                list-style-image: url('../img/li-dot-inverse.png');

                li {
                    a {
                        border-bottom: 0;
                        color: #fff;

                        @media (min-width: 992px) {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }

    &.block-downloads {
        ul {
            list-style-type: none;
            padding-left: 0;

            li {
                padding-bottom: 12px;

                .icon {
                    top: 5px;
                }
            }
        }
    }

    &.block-hr {
        hr {
            border-color: $border-red;

            .home & {
                border-color: $border-green;
            }
        }
    }

    &.block-googlemaps {
        .page-contact .col-sm-6 & {
            .embed-responsive-4by3 {
                @media (min-width: 768px) and (max-width: 991px) {
                    padding-bottom: 100%;
                }
            }
        }
    }

    &.block-bg-red {
        background-color: $bg-red;
        padding: 15px;

        .block-content {
            color: #fff;

            h2 {
                color: #fff;
            }
        }
    }

    .border & {
        border: 1px solid $border-green;
        padding: 15px;
    }
}