.no-fixing {
    .affix,
    .affix-top {
         position: static;
    }
}

.navbar-collapse {
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar-static-top {
    // todo? op mobiel altijd fixed?

    margin-bottom: 0;
    min-height: 55px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;

    @media (min-width: 768px) {
        min-height: 86px;

        @media (max-width: 1199px) {
            &.navbar-open {
                min-height: 174px;
            }
        }
    }

    @media (min-width: 1200px) {
        min-height: 95px;
    }

    .navbar-fixed-toggle {
        color: #333;
        display: none;
        cursor: pointer;
        font-size: 24px;
        position: absolute;
        right: 20px;
        top: 10px;

        .no-fixing & {
            color: #999;
            display: inline-block;
            //-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
            //transform:matrix(-1, 0, 0, 1, 0, 0);
        }

        &:hover {
            color: #000;
        }

        @media (max-height: 662px) and (min-width: 1080px) {
            display: inline-block;
        }
    }

    .navbar-brand {
        padding-top: 0;

        @media (max-width: 767px) {
            height: 75px;
            margin-top: -15px;

            padding-right: 0;
            padding-left: 15px;

            img {
                transition: margin .5s;
                margin-top: 5px;
                width: 200px;
            }
        }
    }

    .no-fixing & {
        @media (min-width: 768px) {
            .navbar-brand {
                margin-top: -20px !important;
            }
        }
    }

    &.affix.navbar-open {
        @media (max-width: 767px) {
            .navbar-brand {
                img {
                    margin-top: 5px;
                }
            }
        }
    }

    &.affix {
        top: 0;
        width: 100%;

        @media (max-width: 767px) {
            .navbar-brand {
                overflow: hidden;

                img {
                    margin-top: 0;
                }
            }
        }

        @media (min-width: 768px) {
            .navbar-brand {
                .breadcrumb-affixed & {
                    height: 101px;
                    margin-top: -20px;
                    overflow: hidden;
                }
            }

            &.navbar-open .navbar-brand {
                .breadcrumb-affixed & {
                    margin-top: -15px;
                }
            }
        }

        @media (min-width: 1200px) {
            .navbar-brand {
                .breadcrumb-affixed & {
                    height: 110px;
                }
            }

            .navbar-nav > li > .dropdown-menu {
                top: 50px;
            }
        }
    }

    .navbar-nav {
        transition: margin .5s;

        @media (max-width: 767px) {
            margin-top: 15px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            margin-right: 170px;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            margin-top: -75px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            margin-right: 190px;
        }

        @media (min-width: 1200px) {
            margin-top: 23px;
        }
    }

    &.affix {
        .navbar-nav {
            .no-fixing & {
                margin-top: 0;

                @media (max-width: 767px) {
                    margin-top: 30px;
                }

                @media (min-width: 768px) and (max-width: 1199px) {
                    margin-top: -75px;
                }

                @media (min-width: 1200px) {
                    margin-top: 23px;
                }
            }
        }
    }

    .navbar-nav > .open > a,
    .navbar-nav > .open > a:hover,
    .navbar-nav > .open > a:focus {
        background-color: transparent;
        color: $text-red;

        span:after {
            display: none;
        }
    }

    .navbar-nav > li > a {
        border-left: 15px solid #fff;
        border-right: 15px solid #fff;
        color: $text-green;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            color: $text-red;

            &:after {
                background-color: $border-red;
                bottom: 0;
                content: '';
                height: 8px;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }
    }

    .navbar-nav > .open > a {
        border-color: #fff;

        &:hover:after {
            display: none;
        }
    }

    .navbar-right .dropdown-menu {
        right: auto;
    }

    .navbar-nav > li > .dropdown-menu {
        background-color: #fff;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin-top: 1px;
        padding: 9px 0 10px 0;

        @media (min-width: 1200px) {
            margin-top: 15px;
            padding: 15px 0 20px 0;

            .ie11 & {
                padding-bottom: 18px;
            }
        }

        &:before {
            border-top: 7px solid $border-red;
            content: '';
            position: absolute;
            top: 0;
            width: 66%;
        }

        li > a:hover,
        li > a:focus {
            background-color: #fff;
        }

        .active {
            a {
                color: $text-red;
            }
        }

        a {
            color: $text-green !important;
            font-size: 17px;
            line-height: 1.42857143 !important;
            padding: 5px 30px;

            @media (min-width: 1200px) {
                padding: 11px 30px;
            }

            &:hover {
                color: $text-red !important;
            }
        }
    }

    .navbar-toggle {
        background: linear-gradient(90deg, $bg-green, $bg-green-lighter);
        border: 0;
        border-radius: 0;
        padding: 20px;
        height: 55px;
        width: 75px;

        @media (max-width: 767px) {
            height: 60px;
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 0;
            padding: 15px 10px 10px 10px;
        }

        @media (min-width: 768px) {
            height: 86px;
            width: 150px;
        }

        .icon-bar {
            background-color: #fff;
            border-radius: 5px;
            display: block;
            height: 4px;
            margin-bottom: 9px;
            width: 100%;

            @media (min-width: 768px) {
                height: 7px;
                margin-bottom: 15px;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        margin-bottom: 0;
        min-height: 86px;

        .navbar-header {
            width: 100%;

            .navbar-toggle {
                display: block;
                float: right;
                margin: 0;
            }

            .navbar-brand {
                margin-top: -15px;
            }
        }

        .navbar-nav {
            li {
                display: none;
            }
            li:last-child {
                display: block;
            }

            li form .input-group {
                img {
                    height: 39px;
                    width: 39px;
                }
                .form-control {
                    font-size: 27px;
                    height: 47px;
                }
            }
        }

        .collapse.in {
            .navbar-nav {
                margin-top: 30px;
                margin-right: 0;

                li {
                    display: block;
                    font-size: 18px;

                    form {
                        padding-top: 5px;
                        padding-right: 0;

                        img {
                            height: 35px;
                            width: 35px;
                        }

                        .form-control {
                            font-size: 23px;
                            height: 43px;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .navbar-nav {
            li form .input-group {
                img {
                    height: 29px;
                    width: 29px;
                }
                .form-control {
                    font-size: 17px;
                    height: 37px;
                }
            }
        }
    }
}

.navbar-default {
    background-color: #fff;
    border: 0;
    font-size: 17px;
}

.nav > li > form {
    position: relative;
    display: block;
    padding: 10px 15px;

    .input-group {
        .input-group-addon {
            background-color: #fff;
            border-color: $text-green;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            padding: 0;

            .btn {
                padding: 0 0 0 1px;
            }
        }

        .form-control {
            border-color: $text-green;
            border-left: 0;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            -webkit-box-shadow: none;
            box-shadow: none;
            font-size: 17px;
            height: 30px;
            padding: 4px 10px;

            &:focus {
                border-color: $border-green;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
    }
}

.navbar-menu {
    background-color: $bg-green;
    background: linear-gradient(90deg, $bg-green, $bg-green-gradient);
    border-radius: 0;
    display: none;
    font-size: 24px;
    margin-bottom: 0;
    min-height: 60px;
    z-index: 100;

    @media (max-width: 767px) {
        position: fixed;
        top: 250px;
        width: 100%;
    }

    @media (min-width: 768px) {
        text-align: center;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
    }

    @media (min-width: 992px) {
        display: block;

        &[style^="display"] {
            display: block !important;
        }
    }

    .navbar-header {
        @media (max-width: 767px) {
            display: none;
        }
    }

    &.navbar-default .navbar-nav {
        display: inline-block;
        float: none;

        & > li > a {
            color: #fff;
            margin-top: 13px;
            margin-bottom: 12px;
            padding: 4px 26px 11px 15px;

            @media (min-width: 768px) {
                border-right: 2px solid #86b2ab;
                padding: 4px 26px 11px 26px;
            }

            &:hover {
                color: $text-red;
            }
        }

        & > li:last-child > a {
            border-right: 0;
        }
    }

    @media (min-width: 768px) {
        #navbar-menu {
            min-height: 60px;
            max-height: 60px;
        }
    }

    &.affix {
        top: 260px;
        width: 100%;

        @media (min-width: 768px) {
            top: 86px;
        }

        @media (min-width: 1200px) {
            top: 95px;
        }

        @media (min-width: 768px) and (max-width: 1199px) {
            .navbar-static-top.navbar-open + .slider + & {
                top: 174px;
            }
        }
    }
}