.page-content {
    img {
        height: auto;
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .page-locatie {
        .row:first-child {
            display: -webkit-flex;
            display: flex;

            -webkit-flex-direction: column;
            flex-direction: column;
            
            .column:first-child {
                -webkit-order: 3;
                order: 3;
            }

            .column:nth-child(2) {
                -webkit-order: 2;
                order: 2;
            }

            .column:last-child {
                -webkit-order: 1;
                order: 1;
            }
        }
    }
}

@media (min-width: 1200px) {
    .page-contact .border .block {
        min-height: 270px;
    }
}

.table-adressen {
    td:last-child {
        white-space: nowrap;
    }
}

.faq-question {
    cursor: pointer;
    font-weight: bold;
    padding-left: 20px;
    position: relative;

    a:before {
        content: '+';
        position: absolute;
        left: 0;
        top: 0;
    }

    &.active a:before {
        content: '-';
    }

    &:hover a {
        color: $text-red;
    }
}
.faq-answer {
    border-bottom: 1px solid $border-red;
    display: none;
    margin-bottom: 1em;
    padding-left: 20px;
}
