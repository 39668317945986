$bg-green-light: #dfe8e7; // 223,232,231
$bg-green: #3f948b; // 63,148,139
$bg-green-gradient: #77aba4; // 119,171,164
$bg-green-lighter: #86b2ab; // 134,178,171
$bg-green-darker: #32766f; // 50,118,111
$bg-light-green-from: rgb(198,223,221);
$bg-light-green-to: rgb(219,232,230);
$bg-red: #ba2d20; // 186,45,32
$bg-red-darker: #98251a; // 152,37,26
$bg-breadcrumb: #cedcda;

$border-green: $bg-green;
$border-red: $bg-red;

$text-green: $bg-green;
$text-red: $bg-red;

// @import 'icomoon.scss';
@import 'nav.scss';
@import 'slider.scss';
@import 'breadcrumb.scss';
@import 'block.scss';
@import 'home.scss';
@import 'pages.scss';
@import 'search.scss';
@import 'sitemap.scss';
@import 'footer.scss';

body {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

h1 {
    color: $text-red;
    font-size: 24px;
    font-weight: 700;
}

h2 {
    color: $text-red;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

hr {
    border-color: $border-red;
}

a.divs-inline-block {
    div {
        display: inline-block;
    }
}

/* todo; nog nodig?
img[style*="width"] {
    height: auto !important;
    width: 100% !important;
}
*/

.news-content,
.search-content,
.page-content {
    margin-bottom: 30px;

    a {
        color: $text-green;
        text-decoration: underline;

        &.btn {
            text-decoration: none;
        }

        &:hover {
            color: $text-red;
        }
    }
}

.img-border {
    border: 1px solid $border-green;
}

.btn {
    font-size: 16px;
}

.btn-border {
    border: 1px solid $border-green;
    border-radius: 0;
}

.btn-bg-green {
    background: $bg-green;
    color: #fff !important;
    display: block;
    padding: 6px 12px;
    text-align: center;
}

.btn-patyna {
    background: $bg-green;
    border-color: $bg-green;
    color: #fff !important;

    &:hover, &:active, &:focus {
        background-color: $bg-green-darker;
        border-color: $bg-green-darker;
    }
}

.btn-patyna-red {
    background: $bg-red;
    border-color: $bg-red;
    color: #fff !important;

    &:hover, &:active, &:focus {
        background-color: $bg-red-darker;
        border-color: $bg-red-darker;
    }
}

.form-control {
    font-size: 16px;
    padding-top: 4px;
    padding-bottom: 4px;

    &.datepicker {
        padding: 4px 12px;
    }
}


.datepicker.datepicker-dropdown {
    margin-top: 60px;

    @media (min-width: 768px) {
        margin-top: 86px;
    }

    @media (min-width: 1200px) {
        margin-top: 95px;
    }

    // todo breadcrumb-affix op not-mobile
}

.addthis_button_whatsapp {
    @media (min-width: 768px) {
        display: none;
    }
}

.locations-aantal {
    color: $text-red;
    font-size: 64px;
    float: right;
    margin-top: -16px;
}

img.locations-phone {
    vertical-align: -8px;
}

ul.check {
    list-style-type: none;
    padding-left: 0;

    li {
        clear: left;

        &:before {
            content: "\e013";
            float: left;
            font-family: 'Glyphicons Halflings';
            height: 32px;
            position: relative;
            top: 2px;
            margin-right: 24px;
        }
    }
}

.red {
    color: $text-red !important;
}
.green {
    color: $text-green !important;
}
.black {
    color: #333 !important;
}

// *** //

.acumin-pro-regular {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
}
.acumin-pro-italic {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
}
.acumin-pro-bold {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 700;
}

.acumin-pro-light {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
}
.acumin-pro-light-italic {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 300;
}

.acumin-pro-semibold {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 600;
}
.acumin-pro-semibold-italic {
    font-family: acumin-pro, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 600;
}

// *** //

.column.mobile-only {
    @media (min-width: 768px) {
        display: none;
    }

    .ge-layout-phone & {
        display: block;
    }
    .ge-layout-tablet & {
        display: block;
        opacity: .5;
    }
    .ge-layout-desktop & {
        display: block;
        opacity: .5;
    }
}

.column.mobile-hide {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }

    .ge-layout-phone & {
        display: block;
        opacity: .5;
    }
    .ge-layout-tablet & {
        display: block;
    }
    .ge-layout-desktop & {
        display: block;
    }
}
