.search-content {
    .marked {
        background-color: #cedcda;
        padding-right: 1px;
        padding-left: 1px;
    }
}

.search-form {
    .input-group-addon {
        background-color: transparent;
        border: 0;
    }
}