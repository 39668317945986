.footer {
    background-image: linear-gradient(90deg, $bg-light-green-from, $bg-light-green-to);
    color: $text-green;
    font-size: 16px;
    font-weight: 700;
    padding: 30px 0;

    .socialmedia {
        padding-top: 15px;

        .row + .row {
            margin-top: 20px;
        }
    }

    .certificaten {
        p {
            margin-bottom: 20px;
        }
    }

    .contact {
        color: $text-red;
        font-style: italic;
        font-weight: 700;
    }

    a {
        color: $text-green;
    }

    img {
        margin-right: 10px;

        @media (min-width: 768px) {
            margin-right: 20px;
        }
    }

    hr {
        border-color: $border-green;
    }

    ul {
        padding-left: 35px;

        li {
            font-weight: normal;
            line-height: 24px;
        }
    }

    @media print {
        display: none;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    .socialmedia {
        a {
            @media(max-width: 992px) {
                display: inline-block;
                float: left;
                margin-bottom: 10px;

                & + a + a {
                    clear: left;

                    & + a {
                        clear: none;
                    }
                }
            }
        }
    }
}

.copyright {
    background-color: $bg-green;
    background: linear-gradient(90deg, $bg-green, $bg-green-gradient);
    color: #fff;
    line-height: 60px;
    padding-bottom: 40px;

    .container {
        position: relative;
    }

    @media (min-width: 768px) {
        font-size: 14px;
    }

    a {
        color: #fff;
    }

    .pull-right {
        padding-right: 60px;
    }

    #topper {
        font-size: 32px;
        position: absolute;
        right: 15px;
        bottom: 0;
    }

    @media print {
        display: none;
    }

    .column:nth-child(2) {
        a + a {
            margin-left: 1em;
        }
    }
}
